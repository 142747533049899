@import '../colors.scss';

@media screen and (max-width: 959px) {
  .footer {
    flex-direction: column;
    justify-content: space-between;
    &-section {
      justify-content: center;
      column-gap: 32px;
      margin: 32px 0;
      * {
        margin: 0;
      }
    }
    .copy-right {
      display: flex;
      justify-content: center;
      margin: 0 auto auto;
    }
  }
}

@media screen and (max-width: 767px) {
  .page-top-button {
    width: 120px;
    height: 120px;
    position: relative;
    margin: 0 auto 40px;
    right: 0;
    bottom: 0;
    svg {
      width: 40px;
      height: 40px;
    }
    &-text {
      font-size: 12px;
      line-height: 12px;
    }
  }

  .home-head {
    .home-header {
      max-height: 56px;
      padding: 16px;
      .home-header-left {
        svg {
          height: 22px;
        }
      }
      .home-header-right {
        .header-menu-button {
          display: none;
        }
        .header-creator-button {
          display: block;
          width: 28px;
          height: 28px;
          img {
            border-radius: 50%;
          }
        }
        .side-menu-button {
          margin-left: 16px;
          display: block;
          cursor: pointer;
          width: 24px;
          height: 24px;
        }
      }
    }

    &-content {
      flex-direction: column;
      gap: 10px;
      padding: 25px 24px 20px;
      .title {
        .main-title {
          span {
            margin-bottom: 24px;
            font-size: min(calc(20px + 6 * ((100vw - 320px) / 55)), 26px);
            line-height: min(calc(20px + 6 * ((100vw - 320px) / 55)), 20px);
          }
        }
        .sub-title {
          width: auto;
          height: 32px;
          div {
            font-size: min(calc(16px + 2 * ((100vw - 320px) / 55)), 18px);
            line-height: 34px;
          }
        }
      }
      .images {
        .profile-image {
          max-width: 343px;
          width: 75vw;
        }
      }
    }
  }

  // 共通style
  .section {
    padding-top: 44px;
    &-title-en {
      height: calc(min(calc(48px + 12 * ((100vw - 320px) / 55)), 60px) + 20px * 2);
      &-text {
        font-size: min(calc(48px + 12 * ((100vw - 320px) / 55)), 60px);
      }
    }
    .section-title {
      p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        font-size: min(calc(16px + 2 * ((100vw - 320px) / 55)), 18px);
        line-height: 18px;
        margin-bottom: 20px;
      }
      img {
        margin: auto;
        max-width: 144px;
      }
    }
    .step {
      height: calc(24px + (20px * 2));
      &-text {
        font-size: 24px;
      }
      &-text:nth-child(2) {
        left: 1px;
        top: 1px;
      }
      &-text:nth-child(3) {
        left: 0.5px;
        top: 0.5px;
      }
    }
  }

  .about {
    &-contents {
      flex-direction: column;
      margin: 40px auto 0;
      max-width: 340px;
      row-gap: 40px;
      align-items: center;
      &-item {
        background-color: transparent;
        width: 100%;
        max-width: 340px;
        padding: 0;
        .about-detail {
          &-title {
            font-size: min(calc(16px + 2 * ((100vw - 320px) / 55)), 18px);
          }
          &-description {
            word-break: keep-all;
            margin-top: 24px;
            font-size: min(calc(13px + 2 * ((100vw - 320px) / 55)), 15px);
            line-height: 25.2px;
          }
        }
      }
      &-images {
        max-width: 310px;
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        &-item {
          width: calc((100% - 8px) / 2);
        }
        &-item:last-child {
          margin-left: 8px;
        }
      }
    }
  }

  .link-in-bio {
    .section-title-en {
      &-body {
        height: calc(min(calc(40px + 12 * ((100vw - 320px) / 55)), 52px) + (20px * 2));
      }
      &-text {
        height: calc(min(calc(40px + 12 * ((100vw - 320px) / 55)), 52px) + (20px * 2));
        font-size: min(calc(40px + 12 * ((100vw - 320px) / 55)), 52px);
      }
      &-text:nth-child(2) {
        left: 8px;
        top: 2px;
      }
      &-text:nth-child(3) {
        left: 4px;
        top: 1px;
      }
    }
    &-contents {
      flex-direction: column-reverse;
      margin: 40px auto 0;
      max-width: 340px;
      align-items: center;
      justify-content: flex-end;

      &-margin {
        margin: 0 0 24px;
        width: 100%;
      }
      &-item {
        background-color: transparent;
        word-break: keep-all;
        width: 100%;
        max-width: 340px;
        padding: 0;

        .link-in-bio-detail {
          &-title {
            font-size: min(calc(16px + 2 * ((100vw - 320px) / 55)), 18px);
            line-height: 180%;
          }
        }
      }
      &-images {
        max-width: 360px;
        width: 96vw;
      }
    }
  }

  .apps {
    &-sub-title {
      max-width: 340px;
      margin: 40px auto;
      text-align: left;
      span {
        display: inline-block;
        font-size: min(calc(16px + 2 * ((100vw - 320px) / 55)), 18px);
      }
    }
    .apps-example {
      max-width: 340px;
      flex-wrap: wrap;
      column-gap: 16px;
      row-gap: 12px;
      margin: auto;
      &-item {
        max-width: 156px;
        width: 42vw;
        border-radius: 16px;
        padding: 24px min(0.4vw, 6px) 16px;
      }
      img {
        width: 21vw;
        height: 21vw;
        max-width: 80px;
        max-height: 80px;
        margin: auto;
      }
      &-title {
        font-size: 12px;
      }
      &-description {
        font-size: 11px;
      }
    }
  }

  .mission {
    margin: 0;
    &-catch-copy {
      max-width: 328px;
      margin: auto;
      span {
        font-size: min(calc(18px + 2 * ((100vw - 320px) / 55)), 20px);
        display: inline-block;
      }
    }

    .first-flow {
      margin-top: 40px;
      padding-bottom: 24px;
      svg {
        height: min(20vw, 72px);
      }
      &-title {
        padding: 0;
        margin: 24px auto 16px;
        max-width: 375px;
        span {
          font-size: min(calc(24px + 5 * ((100vw - 320px) / 55)), 29px);
        }
        .accent {
          font-size: min(calc(29px + 5 * ((100vw - 320px) / 55)), 34px);
        }
      }
    }

    .second-flow {
      padding-bottom: 24px;
      .black-triangle {
        border-top: 32px solid $black;
      }
      &-logo {
        margin-top: 72px;
        margin-bottom: 24px;
        svg {
          height: min(17vw, 64px);
        }
      }
    }

    .third-flow {
      .white-triangle {
        border-top: 32px solid $white;
      }
      .steps {
        margin-top: 48px;
        .step-item {
          display: flex;
          flex-direction: column;
          margin: 40px auto 0;
          max-width: 340px;
          width: auto;
          row-gap: 24px;
          align-items: center;
          &.step1 {
            margin-bottom: 0px;
          }
          &.step2 {
            margin-bottom: 24px;
          }
          &-detail {
            width: 100%;
            &-description {
              &-item {
                justify-content: center;
                font-weight: 700;
                font-size: min(calc(22px + 3 * ((100vw - 320px) / 55)), 25px);
                .under-line-text {
                  .under-line {
                    height: 8px;
                    bottom: 8px;
                  }
                }
              }
            }
          }
          &-image {
            img {
              max-width: 296px;
              width: 78vw;
            }
          }
        }
      }
    }

    .fourth-flow {
      background-color: $white;
      position: relative;
      padding-top: 64px;
      padding-bottom: 40px;
      .beige-triangle {
        border-top: 32px solid #fef9ec;
      }
      .mission-catch-copy {
        span {
          font-size: min(calc(25px + 3 * ((100vw - 320px) / 55)), 28px);
        }
      }
      &-images {
        column-gap: 24px;
        row-gap: 24px;
        max-width: 340px;
        flex-wrap: wrap;
        margin: 32px auto 0;
        img {
          max-width: 152px;
          max-height: 152px;
          aspect-ratio: 1 / 1;
          width: 40vw;
        }
      }
    }
  }

  .how-to {
    .steps {
      .step-item {
        max-width: 328px;
        margin: 40px auto 0;
        &.step1 {
          flex-direction: column;
        }
        &.step2 {
          flex-direction: column-reverse;
          .step-item-detail {
            .step {
              .step-text {
                margin-right: 0;
                margin-left: auto;
                &:nth-child(1) {
                  left: 0;
                  right: auto;
                }
                &:nth-child(2) {
                  left: 1px;
                  right: auto;
                }
                &:nth-child(3) {
                  left: 0.5px;
                  right: auto;
                }
              }
            }
          }
        }
        &.step3 {
          flex-direction: column;
        }
        &-detail {
          width: 100%;
          .step {
            height: calc(24px + (24px * 2));
          }
          &-title {
            text-align: center;
            font-size: 24px;
          }
          &-description {
            text-align: center;
            font-size: 14px;
          }
        }
        &-image {
          margin-top: 40px;
          img {
            max-width: 328px;
            width: 87vw;
          }
        }
      }
    }
  }

  .topics {
    margin: 0;
    &-sub-title {
      margin: 40px auto 24px;
      max-width: 328px;
      text-align: left;
      span {
        display: inline-block;
        font-weight: 500;
        font-size: 14px;
        line-height: 14px;
      }
    }
    .home-swiper {
      max-width: 360px;
      width: 96vw;
      &-slide {
        &-content {
          max-width: 180px;
          width: calc(100% / 2) !important;
        }
        &-avatar {
          img {
            width: 32px;
            height: 32px;
          }
          &-name {
            font-size: 11px;
            line-height: 11px;
            margin-left: 10px;
          }
        }
      }
    }
    .swiper-pagenation-area {
      width: 30vw;
      height: 8px;
      margin: 28px auto 44px;
      .swiper-pagination {
        .swiper-pagination-bullet {
          width: 8px;
          height: 8px;
          margin: 0 8px;
        }
      }
      .swiper-pagenation-button {
        display: none;
      }
    }
    .ready-sector {
      padding: 40px 0;
      &-detail {
        width: 90vw;
        display: flex;
        flex-direction: column;
        max-width: 340px;
        margin: auto auto 40px;
        .left-wrapper {
          .title {
            .main-title {
              div {
                span {
                  font-size: 26px;
                  line-height: 26px;
                  margin-bottom: 24px;
                }
              }
            }

            .sub-title {
              width: auto;
              height: 32px;
              div {
                font-size: 18px;
                line-height: 34px;
              }
            }
          }
        }
        .right-wrapper {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column-reverse;
          .message-image {
            max-width: 327px;
            width: 87vw;
            margin-top: 40px;
          }
          .search-image {
            margin-top: 40px;
            max-width: 312px;
            width: 83vw;
          }
        }
      }
      &-button {
        max-width: 328px;
        max-height: 48px;
        aspect-ratio: 328 / 48;
        width: 87vw;
        background: $fanme-yellow;
        border-radius: 24px;
        font-size: 14px;
        line-height: 14px;
      }
    }
  }

  .contact {
    &-sub-title {
      max-width: 340px;
      margin: 40px auto;
      span {
        font-size: 14px;
        line-height: 180%;
      }
      .next-line-point {
        display: inline-block;
      }
    }
    &-button {
      max-width: 328px;
      max-height: 48px;
      aspect-ratio: 328 / 48;
      width: 87vw;
      border-radius: 24px;
      font-size: 14px;
      line-height: 14px;
      margin: auto auto 80px;
    }
  }

  .footer {
    height: 112px;
    padding: 0;
    .copy-right {
      font-weight: 400;
      font-size: 10px;
      line-height: 10px;
    }
  }

  .side-menu {
    width: 68dvw;
    .side-menu-main {
      margin: 0 24px 0 0;
      .side-menu-close-button {
        margin: 12px 0 28px auto;
        svg {
          width: 24px;
          height: 24px;
        }
      }
      .side-menu-items {
        .side-menu-item {
          margin-bottom: 28px;
          .title {
            font-size: 20px;
            line-height: 20px;
          }
          .description {
            font-size: 10px;
            line-height: 13px;
          }
        }
      }
    }
    .side-menu-bottom {
      display: grid;
      grid-row-gap: 24px;
      width: 100%;
      padding: 0 24px;
      margin-top: 32px;
      button {
        width: 100%;
        height: 48px;
        border: none;
        border-radius: 24px;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        cursor: pointer;
        &.signin-button {
          background: $black;
          color: $white;
        }
        &.signup-button {
          background: $fanme-yellow;
          color: $black;
        }
      }
    }
  }

  .mobile-signup-button {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background: $fanme-yellow;
    position: fixed;
    right: 12px;
    bottom: 8px;
    margin-bottom: 24px;
    z-index: 2;
    box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    cursor: pointer;
    svg {
      width: 18px;
      height: 18px;
    }
    &-text {
      font-weight: 700;
      font-size: 10px;
      line-height: 11px;
      letter-spacing: 0.1em;
      margin: 4px 0;
    }
  }
}

$font-jp: 'Noto Sans JP';
$font-en: 'Noto Sans JP';

* {
  font-family: $font-en, $font-jp, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, source-han-sans-japanese;
}

body {
  font-size: 14px;
  font-weight: 500;
}

/** 見出し */
.jp-title {
  font-family: $font-jp;

  &.x-large {
    font-weight: 700;
    font-size: 18px;
  }

  &.large {
    font-weight: 700;
    font-size: 15px;
  }

  &.middle {
    font-weight: 700;
    font-size: 13px;
  }

  &.small {
    font-weight: 500;
    font-size: 11px;
  }
}

/** 本文 */
.jp-text {
  font-family: $font-jp;

  &.middle {
    font-weight: 400;
    font-size: 13px;
  }

  &.small {
    font-weight: 400;
    font-size: 11px;
  }

  &.x-small {
    font-weight: 400;
    font-size: 10px;
  }
}

/** 本文（英数） */
.en-text {
  font-family: $font-en;

  &.middle {
    font-weight: 500;
    font-size: 14px;
  }

  &.small {
    font-weight: 500;
    font-size: 12px;
  }

  &.x-small {
    font-weight: 500;
    font-size: 10px;
  }
}

$white: #ffffff;
$black: #333333;
$pure-black: #000000;
$gray-1: #999999;
$light-gray-1: #f8f8f8;
$light-gray-2: #eeeeee;
$light-gray-3: #cccccc;
$light-gray-4: #f5f5f5;
$error: #ff3838;
$orange: #ffa800;
$red: #ff5050;

// fanme color
$fanme-yellow: #ffe600;
$fanme-light-yellow: #fff7b3;

$fanme-green: #9bec5b;
$fanme-light-green: #e5f6d9;

$fanme-blue: #50c0ff;
$fanme-light-blue: #d6edf9;

$fanme-purple: #bb85ff;
$fanme-light-purple: #ece1f9;

$fanme-pink: #ff9def;
$fanme-light-pink: #ffebfc;

$fanme-red: #ff8282;
$fanme-light-red: #ffe4e4;

// sns color
$tiktok: #010101;
$twitter: #1da1f2;
$line: #06c755;
$youtube: #ff0000;
$facebook: #1877f2;
$message: #56f368;

@import '../colors.scss';

.page-top-button {
  width: min(10vw, 120px);
  height: min(10vw, 120px);
  border-radius: 50%;
  background: $white;
  position: fixed;
  right: 16px;
  bottom: 16px;
  z-index: 2;
  filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: 0.2s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  &.active {
    opacity: 1;
    visibility: visible;
  }
  svg {
    width: min(3vw, 40px);
    height: min(3vw, 40px);
  }
  &-text {
    font-weight: 500;
    font-size: min(calc(10px + 2 * ((100vw - 768px) / 672)), 12px);
    line-height: min(calc(10px + 2 * ((100vw - 768px) / 672)), 12px);
    letter-spacing: 0.1em;
    margin: 8px 0;
  }
}

.mobile-signup-button {
  display: none;
}

.home {
  background: $light-gray-4;
  color: $black;
  overflow-x: hidden;
  * {
    font-family: 'Montserrat', 'Noto Sans JP' !important;
  }
}

.home-head {
  display: flex;
  flex-direction: column;

  .home-header {
    z-index: 2;
    width: 100%;
    max-height: 104px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: $black;
    padding: 32px min(6vw, 96px) 32px min(6.9vw, 100px);

    .home-header-left {
      display: flex;
      align-items: center;
      svg {
        cursor: pointer;
        height: 40px;
      }
    }

    .home-header-right {
      display: flex;
      align-items: center;
      .header-menu-button {
        width: 136px;
        height: 48px;
        border-radius: 24px;
        font-weight: 700;
        font-size: 15px;
        line-height: 16px;
        letter-spacing: 0.1em;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.signin {
          background: $white;
          color: $black;
        }
        &.signup {
          background: $fanme-yellow;
          color: $black;
          margin-left: 24px;
        }
      }
      .header-creator-button {
        display: none;
      }
      .side-menu-button {
        margin-left: 40px;
        display: block;
        cursor: pointer;
        width: 64px;
        height: 64px;
      }
    }
  }

  &-content {
    position: relative;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px calc(100vw * 0.06) 28px;
    width: 100%;
    gap: min(3vw, 40px);

    .title {
      max-width: 480px;
      .main-title {
        span {
          display: inline-block;
          margin-bottom: 32px;
          font-weight: 900;
          font-size: min(calc(30px + 24 * ((100vw - 768px) / 672)), 54px);
          line-height: min(calc(26px + 24 * ((100vw - 768px) / 672)), 50px);
          letter-spacing: 0.25em;
        }
      }

      .sub-title {
        width: fit-content;
        height: 56px;
        padding: 0 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(83.38deg, #8c1ee6 7.06%, #ff55af 50.93%, #ffc814 94.8%);
        div {
          font-weight: 700;
          font-size: min(calc(18px + 14 * ((100vw - 768px) / 672)), 32px);
          line-height: min(calc(20px + 14 * ((100vw - 768px) / 672)), 34px);
          letter-spacing: 0.1em;
          color: $white;
        }
      }
    }
    .images {
      display: flex;
      justify-content: space-between;

      .profile-image {
        max-width: 695px;
        max-height: 663px;
        aspect-ratio: 695 / 693;
        width: 36vw;
      }
    }
  }
}
.banner {
  padding: 0px calc(100vw * 0.06) 50px;
  img {
    max-width: 900px;
    width: 100%;
    height: auto;
  }
}

// 共通style
.section {
  background: $light-gray-4;
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;
  &-title-en {
    position: relative;
    width: 100%;
    height: calc(74px + (24px * 2));
    left: 0;
    top: 0;
    &-text {
      display: inline;
      position: absolute;
      width: fit-content;
      right: 0;
      margin: auto;
      font-weight: 600;
      font-size: 74px;
      line-height: 180%;
      letter-spacing: 0.05em;
    }
    &-text:nth-child(1) {
      left: 0;
      top: 0;
      background: linear-gradient(38.38deg, #0050d2 16.75%, #00b9e6 50%, #00e69b 85.42%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-text:nth-child(2) {
      left: 8px;
      top: 2px;
      background: linear-gradient(38.38deg, #8c1ee6 14.58%, #ff55af 50%, #ffc814 85.42%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-text:nth-child(3) {
      left: 4px;
      top: 1px;
      color: $white;
      text-shadow: 1px 1px 0 $black, -1px 1px 0 $black, 1px -1px 0 $black, -1px -1px 0 $black;
    }
  }

  .section-title {
    display: flex;
    flex-direction: column;
    text-align: center;
    p {
      color: $black;
      font-weight: 700;
      font-size: 26px;
      line-height: 26px;
      letter-spacing: 0.1em;
      margin: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 24px;
    }
    img {
      margin: auto;
      max-width: 180px;
    }
  }

  .step {
    position: relative;
    width: 100%;
    height: calc(min(calc(30px + 10 * ((100vw - 768px) / 672)), 40px) + 24px * 2);
    left: 0;
    top: 0;
    &-text {
      display: inline;
      position: absolute;
      width: fit-content;
      right: 0;
      margin-left: 0;
      font-weight: 700;
      font-size: min(calc(30px + 10 * ((100vw - 768px) / 672)), 40px);
      line-height: 180%;
      letter-spacing: 0.1em;
    }
    &-text:nth-child(1) {
      left: 0;
      top: 0;
      background: linear-gradient(38.38deg, #0050d2 16.75%, #00b9e6 50%, #00e69b 85.42%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-text:nth-child(2) {
      left: 4px;
      top: 2px;
      background: linear-gradient(38.38deg, #8c1ee6 14.58%, #ff55af 50%, #ffc814 85.42%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
    &-text:nth-child(3) {
      left: 2px;
      top: 1px;
      color: $white;
      text-shadow: 0.5px 0.5px 0 $black, -0.5px 0.5px 0 $black, 0.5px -0.5px 0 $black,
        -0.5px -0.5px 0 $black;
    }
  }
}

// ABOUT
.about {
  &-contents {
    display: flex;
    justify-content: space-between;
    margin: 64px auto 0;
    max-width: 1200px;
    max-height: 560px;
    column-gap: 3vw;

    &-item {
      background-color: $white;
      border-radius: 24px;
      display: flex;
      align-items: center;
      width: 47vw;
      max-width: 612px;
      padding: 0 min(2vw, 32px) 0 min(2.5vw, 40px);
      .about-detail {
        &-title {
          font-weight: 700;
          font-size: min(calc(16px + 14 * ((100vw - 768px) / 672)), 30px);
          line-height: 200%;
        }
        &-description {
          margin-top: min(3vw, 40px);
          font-weight: 500;
          font-size: min(calc(13px + 9 * ((100vw - 768px) / 672)), 22px);
          line-height: 1.8;
        }
      }
    }
    &-images {
      max-width: 544px;
      width: 37vw;
      display: flex;

      &-item {
        width: calc((100% - 16px) / 2);
        max-width: 264px;
        max-height: 560px;
        aspect-ratio: 264 / 560;
      }
      &-item:last-child {
        margin-left: 16px;
      }
    }
  }
}

.link-in-bio {
  &-contents {
    display: flex;
    justify-content: space-between;
    margin: 40px auto 0;
    max-width: 1200px;
    max-height: 592px;
    column-gap: 2vw;

    &-margin {
      margin: 24px 0;
    }

    &-item {
      background-color: $white;
      border-radius: 24px;
      display: flex;
      align-items: center;
      max-width: 676px;
      width: 50vw;
      height: 100%;
      padding: 0 min(5vw, 82px);

      .link-in-bio-detail {
        &-title {
          font-weight: 700;
          font-size: min(calc(16px + 14 * ((100vw - 768px) / 672)), 30px);
          line-height: 200%;
        }
      }
    }
    &-images {
      max-width: 510px;
      width: 36vw;
      &-item {
        width: 100%;
      }
    }
  }
}

.apps {
  &-sub-title {
    display: block;
    align-items: center;
    text-align: center;
    margin: 64px 0;
    span {
      font-weight: 700;
      font-size: min(calc(18px + 12 * ((100vw - 768px) / 672)), 30px);
      line-height: 180%;
    }
  }

  .apps-example {
    display: flex;
    align-items: center;
    justify-content: space-between;
    column-gap: min(3vw, 40px);
    width: 90%;
    max-width: 1016px;
    margin: auto;
    &-item {
      background: $white;
      border-radius: 20px;
      text-align: center;
      position: relative;
      padding-top: 32px;
      padding-bottom: 24px;
      max-width: 224px;
      width: calc(100% - min(3vw, 40px) / 4);
      display: grid;
      img {
        width: 11vw;
        height: 11vw;
        max-width: 160px;
        max-height: 160px;
        margin: 0 auto;
      }
      &-title {
        margin: 16px 0;
        font-weight: 700;
        font-size: min(calc(14px + 4 * ((100vw - 768px) / 672)), 18px);
        line-height: min(calc(14px + 4 * ((100vw - 768px) / 672)), 18px);
      }
      &-description {
        font-weight: 500;
        font-size: min(calc(12px + 4 * ((100vw - 768px) / 672)), 16px);
        line-height: 180%;
      }
    }
  }
}

.mission {
  margin: 0;
  text-align: center;

  &-catch-copy {
    span {
      font-weight: 700;
      font-size: min(calc(25px + 13 * ((100vw - 768px) / 672)), 38px);
      line-height: 180%;
      letter-spacing: 0.1em;
      background: linear-gradient(83.38deg, #8c1ee6 7.06%, #ff55af 50.93%, #ffc814 94.8%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
    }
  }

  .first-flow {
    background: $black;
    margin-top: 72px;
    width: 100vw;
    svg {
      width: auto;
      height: min(calc(96px + 24 * ((100vw - 768px) / 672)), 120px);
      margin-left: auto;
      margin-right: auto;
    }
    &-title {
      text-align: center;
      margin-top: 72px;
      margin-bottom: 72px;
      span {
        display: inline-block;
        color: $white;
        font-weight: 700;
        font-size: min(calc(30px + 8 * ((100vw - 768px) / 672)), 38px);
        line-height: 180%;
      }
      .accent {
        color: $red;
      }
    }
  }

  .second-flow {
    position: relative;
    background-color: $white;
    width: 100vw;
    padding-bottom: 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .black-triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: -1px;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 72px solid $black;
      border-right: 50vw solid transparent;
      border-left: 50vw solid transparent;
      z-index: 1;
    }
    &-logo {
      margin-top: 152px;
      margin-bottom: 56px;
      svg {
        height: min(8vw, 88px);
      }
    }
  }

  .third-flow {
    background-color: #fef9ec;
    position: relative;
    width: 100vw;
    .white-triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: -1px;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 72px solid $white;
      border-right: 50vw solid transparent;
      border-left: 50vw solid transparent;
      z-index: 1;
    }
    .steps {
      margin-top: 112px;
      .step-item {
        max-width: 1000px;
        width: 84vw;
        margin: 0 auto;
        display: grid;
        grid-template-columns: 1fr min(21vw, 296px);
        align-items: center;
        justify-content: center;
        column-gap: min(4vw, 68px);
        &.step1 {
          margin-bottom: 80px;
        }
        &.step2 {
          margin-bottom: 48px;
        }
        &-detail {
          &-description {
            &-item {
              display: flex;
              color: $black;
              font-weight: 900;
              font-size: min(calc(26px + 16 * ((100vw - 768px) / 672)), 42px);
              line-height: 180%;
              letter-spacing: 0.03em;
              text-align: left;
              .under-line-text {
                position: relative;
                z-index: 1;
                .under-line {
                  background: $fanme-yellow;
                  position: absolute;
                  z-index: -1;
                  width: 100%;
                  height: 10px;
                  left: 0;
                  bottom: 20px;
                }
              }
            }
          }
        }
        &-image {
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            max-width: 296px;
            width: 21vw;
          }
        }
      }
    }
  }

  .fourth-flow {
    background-color: $white;
    position: relative;
    padding-top: 152px;
    padding-bottom: 80px;
    width: 100vw;
    .beige-triangle {
      position: absolute;
      width: 0;
      height: 0;
      top: -1px;
      left: 0;
      right: 0;
      margin: auto;
      border-top: 72px solid #fef9ec;
      border-right: 50vw solid transparent;
      border-left: 50vw solid transparent;
      z-index: 1;
    }
    &-images {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 56px;
      column-gap: 32px;
      img {
        max-width: 224px;
        max-height: 224px;
        aspect-ratio: 1 / 1;
        width: 16vw;
      }
    }
  }
}

.how-to {
  .steps {
    .step-item {
      max-width: 1200px;
      margin: 64px auto 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      column-gap: min(4vw, 68px);
      &-detail {
        &-title {
          color: $black;
          font-weight: 900;
          font-size: min(calc(24px + 18 * ((100vw - 768px) / 672)), 42px);
          line-height: 160%;
          letter-spacing: 0.03em;
        }
        &-description {
          color: $black;
          font-weight: 700;
          font-size: min(calc(14px + 6 * ((100vw - 768px) / 672)), 20px);
          line-height: 180%;
          letter-spacing: 0.03em;
          margin-top: 16px;
          .under-line-text {
            position: relative;
            z-index: 1;
            .under-line {
              background: $fanme-yellow;
              position: absolute;
              z-index: -1;
              width: 100%;
              height: 10px;
              left: 0;
              bottom: 20px;
            }
          }
        }
      }
      &.step2 {
        .step-item-detail {
          text-align: right;
          .step {
            .step-text {
              margin-right: 0;
              margin-left: auto;
              &:nth-child(1) {
                right: 4px;
                left: auto;
              }
              &:nth-child(2) {
                right: 0;
                left: auto;
              }
              &:nth-child(3) {
                right: 2px;
                left: auto;
              }
            }
          }
        }
      }
      &-image {
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          max-width: 656px;
          max-height: 560px;
          aspect-ratio: 656 / 560;
          width: calc(100vw * 0.46);
        }
      }
    }
  }
}

.topics {
  margin: 0;
  &-sub-title {
    text-align: center;
    margin: 64px 0;
    span {
      font-weight: 700;
      font-size: min(calc(16px + 5 * ((100vw - 768px) / 672)), 21px);
      line-height: 180%;
      color: $black;
    }
  }

  .home-swiper {
    max-width: 1032px;
    width: 72vw;
    &-slide {
      width: 100%;
      display: flex;
      cursor: pointer;
      &-content {
        max-width: 260px;
        width: calc(100% / 4) !important;
      }
      &-image {
        width: 100%;
      }
      &-avatar {
        display: flex;
        align-items: center;
        padding-left: 16px;
        img {
          margin: 0;
          width: min(3vw, 40px);
          height: min(3vw, 40px);
        }
        &-name {
          font-weight: 500;
          font-size: 14px;
          line-height: 14px;
          margin-left: 8px;
        }
      }
    }
  }
  .swiper-pagenation-area {
    max-width: 272px;
    width: 20vw;
    height: 48px;
    margin: 56px auto 80px;
    position: relative;
    display: flex;
    align-items: center;
    .swiper-pagination {
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      height: fit-content;
      .swiper-pagination-bullet {
        background: $light-gray-2;
        width: min(1.2vw, 16px);
        height: min(1.2vw, 16px);
        border-radius: 50%;
        margin: 0 12px;
        box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
      }

      .swiper-pagination-bullet-active {
        background-color: $fanme-yellow !important;
      }
    }
    .swiper-pagenation-button {
      background: $white;
      width: min(3vw, 48px);
      height: min(3vw, 48px);
      border-radius: 50%;
      box-shadow: 0px 0px 16px rgba(0, 0, 0, 0.08);
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      top: 0;
      bottom: 0;
      margin: auto;
      &.swiper-button-prev {
        left: 0;
        right: auto;
      }
      &.swiper-button-next {
        right: 0;
        left: auto;
      }
      &.swiper-button-prev:after,
      &.swiper-button-next:after {
        font-size: min(calc(11px + 7 * ((100vw - 768px) / 672)), 20px);
        color: black;
      }
    }
  }

  .ready-sector {
    background-color: $white;
    padding: 80px 0;
    width: 100vw;
    &-detail {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      align-items: center;
      max-width: 1200px;
      width: 80vw;
      margin: auto auto 68px;
      .left-wrapper {
        .title {
          .main-title {
            div {
              span {
                display: inline-flex;
                font-weight: 900;
                font-size: min(calc(32px + 22 * ((100vw - 768px) / 672)), 54px);
                line-height: min(calc(28px + 22 * ((100vw - 768px) / 672)), 50px);
                letter-spacing: 0.25em;
                margin-bottom: 32px;
              }
            }
          }

          .sub-title {
            width: fit-content;
            height: 56px;
            padding: 0 16px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: linear-gradient(83.38deg, #8c1ee6 7.06%, #ff55af 50.93%, #ffc814 94.8%);
            div {
              font-weight: 700;
              font-size: min(calc(22px + 10 * ((100vw - 768px) / 672)), 32px);
              line-height: min(calc(24px + 10 * ((100vw - 768px) / 672)), 34px);
              letter-spacing: 0.1em;
              color: $white;
            }
          }
        }
      }
      .right-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .message-image {
          max-width: 523px;
          width: 36vw;
        }
        .search-image {
          margin-top: 26px;
          max-width: 476px;
          width: 33vw;
          filter: drop-shadow(0px 0px 16px rgba(0, 0, 0, 0.08));
          cursor: pointer;
        }
      }
    }
    &-button {
      max-width: 724px;
      max-height: 104px;
      aspect-ratio: 724 / 104;
      width: 50vw;
      background: $fanme-yellow;
      border-radius: 52px;
      color: $black;
      font-weight: 700;
      font-size: min(calc(22px + 8 * ((100vw - 768px) / 672)), 30px);
      line-height: min(calc(22px + 8 * ((100vw - 768px) / 672)), 30px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      cursor: pointer;
    }
  }
}

.contact {
  &-sub-title {
    text-align: center;
    margin: 64px auto;
    span {
      font-weight: 400;
      font-size: min(calc(20px + 4 * ((100vw - 768px) / 672)), 24px);
      line-height: 44px;
    }
    .bold-accent {
      font-weight: 700;
    }
    .contact-link {
      color: $black;
    }
  }
  &-button {
    max-width: 536px;
    max-height: 104px;
    aspect-ratio: 536 / 104;
    width: 37vw;
    background: $black;
    border-radius: 52px;
    color: $white;
    font-weight: 700;
    font-size: min(calc(22px + 8 * ((100vw - 768px) / 672)), 30px);
    line-height: min(calc(22px + 8 * ((100vw - 768px) / 672)), 30px);
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto auto 160px;
    cursor: pointer;
  }
}

.content-header {
  position: relative;
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 36px;

  &:before {
    z-index: 0;
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 0;
    width: 0;
    border-style: solid;
    border-color: $light-gray-2 transparent transparent transparent;
    border-width: 200px 200px 0 0;
  }

  .label {
    padding: 32px;
    z-index: 1;
    position: absolute;

    &:first-child {
      top: 0;
      left: 0;
    }

    &:last-child {
      bottom: 0;
      right: 0;
    }

    .title {
      font-size: 80px;
      font-style: italic;
      font-weight: 600;
      line-height: 62px;
    }

    .sub-title {
      font-size: 20px;
      font-weight: 500;
      line-height: 16px;
      margin-top: 32px;
    }
  }

  .right-label {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    text-align: right;
    font-size: 24px;
    line-height: 44px;
    font-weight: 400;

    span {
      font-weight: 700;
    }
  }
}

.footer {
  width: 100%;
  min-height: 120px;
  background: $black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px min(7vw, 100px);
  font-weight: 400;

  &-section {
    word-break: keep-all;
    display: flex;
    font-size: min(calc(14px + 2 * ((100vw - 768px) / 672)), 16px);
    line-height: min(calc(14px + 2 * ((100vw - 768px) / 672)), 16px);

    * {
      text-decoration: none;
      color: $white;
      margin-right: min(6vw, 80px);
    }
  }

  .copy-right {
    color: $gray-1;
    font-weight: 500;
    font-size: min(calc(11px + 1 * ((100vw - 768px) / 672)), 12px);
    line-height: min(calc(11px + 1 * ((100vw - 768px) / 672)), 12px);
    letter-spacing: 0.1em;
  }
}

.side-menu-bg {
  display: block;
  background: $pure-black;
  opacity: 0.3;
  position: fixed;
  z-index: 3;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  overflow-x: hidden;
  width: 100%;
  height: 100vh;
  &.visible {
    transform: translateX(0);
  }
}

.side-menu {
  display: block;
  background: $white;
  position: fixed;
  z-index: 4;
  top: 0;
  right: 0;
  transform: translateX(100%);
  opacity: 0;
  overflow-x: hidden;
  text-align: center;
  width: 320px;
  height: 100dvh;
  transition: all 0.3s;
  &.visible {
    transform: translateX(0);
    opacity: 1;
  }

  .side-menu-main {
    margin: 0 min(7vw, 104px) 0 0;
    .side-menu-close-button {
      display: flex;
      justify-content: flex-end;
      margin: 35px 0 116px auto;
      cursor: pointer;
      svg {
        width: 35px;
        height: 35px;
      }
    }

    .side-menu-items {
      text-align: right;
      .side-menu-item {
        margin-bottom: 50px;
        .title {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          color: $black;
          font-weight: 600;
          font-size: 42px;
          line-height: 58px;
          letter-spacing: 0.03em;
          cursor: pointer;
        }
        .description {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          font-weight: 500;
          font-size: 15px;
          line-height: 13px;
          letter-spacing: 0.03em;
          color: #6981a6;
        }
      }
    }
  }

  .side-menu-bottom {
    display: none;
  }
}
